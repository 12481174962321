import React from "react";
import ParticlesBackground from "./ParticlesBackground";
import Header from "./Header";
import CustomCursor from "./CustomCursor";
import RotateMessage from "./RotateMessage";
import "./AboutPage.css"; // Make sure to update your CSS accordingly

const AboutPage = ({ isInverted }) => {
    return (
        <div className={`App ${isInverted ? "inverted" : ""}`}>
            <ParticlesBackground isInverted={isInverted} />
            <Header isInverted={isInverted} />
            <div className="about-content">
                <header className="App-header">
                    <div className={`text-box ${isInverted ? "inverted-bg" : "dark-bg"}`}>
                        <h1>About Me</h1>
                        <div className="columns-container">
                            {/* Column 1: AI/ML Expertise */}
                            <div className="column">
                                <div className="icon-wrapper">
                                    {/* Example using Font Awesome icon */}
                                    <i className="fas fa-robot fa-5x"></i>
                                </div>
                                <h2>AI & Machine Learning</h2>
                                <p>
                                    I am a professional <strong>AI developer</strong> currently studying <strong>Artificial Intelligence</strong> at the <strong>University of Pavia</strong>. 
                                </p>
                                <p>
                                    I have hands-on experience with <strong>Python</strong>, <strong>Keras</strong>, and <strong>TensorFlow</strong>. I’ve developed <strong>chatbots</strong>, <strong>voice assistants</strong>, and <strong>AI-enabled education systems</strong>, applying state-of-the-art <strong>machine learning algorithms</strong> and <strong>neural networks</strong>.
                                </p>
                            </div>

                            {/* Column 2: Backend & Frontend */}
                            <div className="column">
                                <div className="icon-wrapper">
                                    <i className="fas fa-code fa-5x"></i>
                                </div>
                                <h2>Full-Stack Development</h2>
                                <p>
                                    On the <strong>backend</strong>, I use <strong>Django</strong> and <strong>FastAPI</strong> to create secure, scalable architectures and RESTful APIs.
                                </p>
                                <p>
                                    For the <strong>frontend</strong>, I specialize in <strong>React</strong>, <strong>Laravel</strong>, and modern web standards (<strong>HTML5</strong>, <strong>CSS3</strong>, <strong>JavaScript</strong>) to build responsive, user-focused interfaces.
                                </p>
                                <p>
                                    I also maintain <strong>Linux-based environments</strong>, manage <strong>Bash</strong> scripts, use <strong>Git</strong> for version control, and handle <strong>system administration</strong>. For data storage, I leverage <strong>SQL</strong> and <strong>MySQL</strong> for efficient database management.
                                </p>
                            </div>

                            {/* Column 3: Projects */}
                            <div className="column">
                                <div className="icon-wrapper">
                                    {/* Example using Font Awesome icon */}
                                    <i className="fas fa-project-diagram fa-5x"></i>
                                </div>
                                <h2>AI/ML Projects</h2>
                                <p>
                                    <strong>AI Tutor</strong>: An NLP-powered tutor offering educational insights.
                                </p>
                                <p>
                                    <strong>Personal Assistant</strong>: A TensorFlow-based assistant for managing tasks and schedules.
                                </p>
                                <p>
                                    <strong>Aidventure</strong>: An AI-driven storytelling platform with React & Node.js.
                                </p>
                                <p>
                                    <strong>Monumetor</strong>: A monument recognition app delivering historical insights using image recognition.
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
            <CustomCursor isInverted={isInverted} />
            <RotateMessage isInverted={isInverted} />
        </div>
    );
};

export default AboutPage;
