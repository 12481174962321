import React from "react";
import ParticlesBackground from "./ParticlesBackground";
import Header from "./Header";
import CustomCursor from "./CustomCursor";
import RotateMessage from "./RotateMessage";
import "./PortfolioPage.css"; // Same stylesheet

const projects = [

    {
        title: "Aidventure",
        description: "An interactive storytelling platform using AI to create personalized user experiences.",
        link: "https://github.com/Ertoip/aidventure",
        icon: "fas fa-book-open"
    },
    {
        title: "Monumetor",
        description: "Mobile app providing historical insights on monuments via AI and image recognition.",
        link: "https://github.com/Ertoip/monumentor",
        icon: "fas fa-landmark"
    },
    {
        title: "AI Tutor",
        description: "An AI-powered tutor that analyzes files and generates insights using NLP and deep learning.",
        link: "https://github.com/Ertoip/teacher",
        icon: "fas fa-robot"
    },
    {
        title: "Personal Assistant",
        description: "A productivity tool for task management, developed with Python and TensorFlow.",
        link: "https://github.com/Ertoip/assistant",
        icon: "fas fa-user-cog"
    },
];

const PortfolioPage = ({ isInverted }) => {
    return (
        <div className={`App ${isInverted ? "inverted" : ""}`}>
            <ParticlesBackground isInverted={isInverted} />
            <Header isInverted={isInverted} />
            <div className="portfolio-content">
                <header className="App-header">
                    <div className={`text-box ${isInverted ? "inverted-bg" : "dark-bg"}`}>
                        <h1>My Projects</h1>
                        <div className="columns-container">
                            {projects.map((project, index) => (
                                <a
                                    key={index}
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="column-link"
                                >
                                    <div className="icon-wrapper">
                                        <i className={`${project.icon} fa-5x`}></i>
                                    </div>
                                    <h2>{project.title}</h2>
                                    <p>{project.description}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                </header>
            </div>
            <CustomCursor isInverted={isInverted} />
            <RotateMessage isInverted={isInverted} />
        </div>
    );
};

export default PortfolioPage;
