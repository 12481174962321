import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomePage from './components/HomePage';
import InvertedPage from './components/InvertedPage';
import AboutPage from './components/AboutPage';
import PortfolioPage from './components/PortfolioPage';
import './App.css';

function App() {
    return (
        <Router>
            <Helmet>
                <title>Pietro Avanzini</title>
                <link rel="icon" type="image/png" href="/black-circle.png" />
            </Helmet>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/inverted" element={<InvertedPage />} />
                <Route path="/inverted/about" element={<AboutPage isInverted />} />
                <Route path="/inverted/portfolio" element={<PortfolioPage isInverted />} />
            </Routes>
        </Router>
    );
}

export default App;
